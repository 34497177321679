import React from "react";
import { navigate,Link } from "gatsby"
//import TreeView from "@material-ui/lab/TreeView";
import TreeView from '@mui/lab/TreeView';
//import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
//import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
//'@mui/icons-material/Info';
//import TreeItem from "@material-ui/lab/TreeItem";
import TreeItem from '@mui/lab/TreeItem';
//import { Checkbox, FormControlLabel } from "@material-ui/core";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
//import { RenderTree, data } from "./sampleData";
//import { RenderTree, data } from "./usmlewiz_treeview.v1.mod.temp";
import { RenderTree, data } from "assets/usmlewiz_treeview.v1.mod.temp.tsx";
 import   Button from "@mui/material/Button"
import TextField from '@mui/material/TextField';




const MAX_QUESTION_PER_BLOCK=44

//5230^^Vascular|Physiology,5228^^Vascular|Physiology,5221^^Vascular|Physiology,5217^^Vascular|Physiology,5210^^Vascular|Physiology,
function DecodeUsmlewizID(selectedids){
 let count = []
 let idArray =[]
  selectedids.map((data,index)=>{
    if(typeof data == "string"){
    if(data.includes(",")){
    var individualid = data.split(",")
    //individualq.filter(n => n)
    count.push(individualid)
    individualid.map((singleq,index)=>{
      if(singleq.includes("^^")){
 let id_name= singleq.split("^^")
      let id = id_name[0]
      let name = id_name[1]
idArray.push(id)
      }
     
    })
  }}
  })
  return idArray
 

}

export default function RecursiveTreeView() {
  const [selected, setSelected] = React.useState<string[]>([]);
 const [maxQuestionPerBlock, setmaxQuestionPerBlock] = React.useState("0");


 function shuffle(array) {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}


function GetRandomNElementFromArray(anArray,NumberOfElement){
 /* var shuffled = anArray.sort(function(){return .5 - Math.random()});


return shuffled.slice(0,NumberOfElement);
*/

// return anArray.sort(() => .5 - Math.random()).slice(0,NumberOfElement)

return shuffle(anArray).slice(0, NumberOfElement);


}
 function HandleGenerateTest(){
   if(maxQuestionPerBlock>>MAX_QUESTION_PER_BLOCK){
     setmaxQuestionPerBlock(MAX_QUESTION_PER_BLOCK)
   }
   console.log("aye")
   var idArray = DecodeUsmlewizID(selected)
 //console.log("idArray.length",idArray)
// console.log("idArray.length",idArray.length)
   var finalrandom_array_of_qid=GetRandomNElementFromArray(idArray,maxQuestionPerBlock)
  


   navigate(
        "/usmle/uworld/uworld with medangle theme",
        {
          state: { qids:finalrandom_array_of_qid },
        }
      )


  // console.log("finalrandom_array_of_qid.length",finalrandom_array_of_qid)
   //console.log("finalrandom_array_of_qid",finalrandom_array_of_qid.length)
 }

function HandleMaxQuestionPerBlock(event){
  if(event.target.value >> MAX_QUESTION_PER_BLOCK){
setmaxQuestionPerBlock(MAX_QUESTION_PER_BLOCK)
}
  else{
setmaxQuestionPerBlock(event.target.value)

}
}

  function getChildById(node: RenderTree, id: string) {
    let array: string[] = [];

    function getAllChild(nodes: RenderTree | null) {
      if (nodes === null) return [];
      array.push(nodes.id);
      if (Array.isArray(nodes.children)) {
        nodes.children.forEach(node => {
          array = [...array, ...getAllChild(node)];
          array = array.filter((v, i) => array.indexOf(v) === i);
        });
      }
      return array;
    }

    function getNodeById(nodes: RenderTree, id: string) {
      if (nodes.id === id) {
        return nodes;
      } else if (Array.isArray(nodes.children)) {
        let result = null;
        nodes.children.forEach(node => {
          if (!!getNodeById(node, id)) {
            result = getNodeById(node, id);
          }
        });
        return result;
      }

      return null;
    }

    return getAllChild(getNodeById(node, id));
  }

  function getOnChange(checked: boolean, nodes: RenderTree) {
    const allNode: string[] = getChildById(data, nodes.id);
    let array = checked
      ? [...selected, ...allNode]
      : selected.filter(value => !allNode.includes(value));

    array = array.filter((v, i) => array.indexOf(v) === i);

    setSelected(array);
  }

  const renderTree = (nodes: RenderTree) => (
    <TreeItem
      key={nodes.id}
      nodeId={nodes.id}
      label={
        <FormControlLabel
          control={
            <Checkbox
              checked={selected.some(item => item === nodes.id)}
              onChange={event =>
                getOnChange(event.currentTarget.checked, nodes)
              }
             
            sx={{'& .MuiSvgIcon-root': { fontSize: 28 }
         
        }}
              onClick={e => e.stopPropagation()}
            />
          }
          label={<>{nodes.name}</>}
          key={nodes.id}
        />
      }
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map(node => renderTree(node))
        : null}
    </TreeItem>
  );

  return (
    <>

    
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpanded={["root", "3", "4"]}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      {renderTree(data)}
    </TreeView>
    <TextField type="number" id="outlined-basic" label="No. of Questions" variant="outlined" value={maxQuestionPerBlock} onChange={HandleMaxQuestionPerBlock/*(event)=>{setmaxQuestionPerBlock(event.target.value)}*/} 
   
  
    inputProps={{max: MAX_QUESTION_PER_BLOCK, min: 1 , inputmode: 'numeric', pattern: '[0-9]*' }} />
    <Button variant="outlined" onClick={HandleGenerateTest} >Generate Test</Button>

    {/*maxQuestionPerBlock:{maxQuestionPerBlock}<br/>
    length:{selected.length}<br/>
    decode.length:{DecodeUsmlewizID(selected).length}<br/>
    decode:{DecodeUsmlewizID(selected)}<br/>
    selected:{selected}*/}
    
    </>
  );
}
